






import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import CurrencySection from '../sections/currency.section.vue';

@Component({
    components: {
        CurrencySection,
    },
})
export default class ScanPropertiesTab extends Vue {
    static title = 'Scan Properties';
}
