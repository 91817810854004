

























import {
    Vue,
    Component,
} from 'vue-property-decorator';
import { SubpageControllerChild } from '../../interfaces/subpage-controller.interface';

@Component({
    inject: ['setSubpage'],
})
export default class EmailReportsSection extends Vue implements SubpageControllerChild {
    public setSubpage!: SubpageControllerChild['setSubpage'];

    public openSubpage() {
        this.setSubpage('emailReports');
    }
}
