




















































import { Component, Vue } from 'vue-property-decorator';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import ReportPropertiesTab from '../components/tabs/reports-properties.tab.vue';
import IntradaysPropertiesTab from '../components/tabs/intradays-properties.tab.vue';
import ScanPropertiesTab from '../components/tabs/scan-properties.tab.vue';
import AlertsAndNotificationsTab from '../components/tabs/alerts-notifications.tab.vue';
import ColorPalette from '../components/tabs/color-palette.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
    },
})
export default class SettingsPage extends Vue {
    tabs = [
        ScanPropertiesTab,
        IntradaysPropertiesTab,
        AlertsAndNotificationsTab,
        ReportPropertiesTab,
        ColorPalette,
    ];

    currentTab = 0;

    mounted() {
        this.currentTab = +this.$route.query.tab || 0;
    }

    setTab(index: number) {
        this.currentTab = index;
        this.updateQueryParameter();
    }

    updateQueryParameter() {
        if (this.currentTab === +this.$route.query.tab) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: { tab: String(this.currentTab) },
        });
    }
}
