import { render, staticRenderFns } from "./subpage-controller.vue?vue&type=template&id=2c697932&"
import script from "./subpage-controller.vue?vue&type=script&lang=ts&"
export * from "./subpage-controller.vue?vue&type=script&lang=ts&"
import style0 from "./subpage-controller.vue?vue&type=style&index=0&id=2c697932&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports