



















































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class IntradaysSettings extends Vue {
    static title = 'Intradays';

    @Inject(UserServiceS) userService!: UserService;
    @Inject(UserSettingsS) userSettings!: UserSettingsService;

    isChanged = false;
    statusText = '';

    get email() {
        return this.userService.user!.email;
    }

    get intraday() {
        return this.userSettings.intradaySettings;
    }

    get isSaving() {
        return this.userSettings.isSaving;
    }

    updateChangeState() {
        this.isChanged = true;
    }

    validateSettings() {
        const { max, min } = Math;
        const {
            rateChangePercent2Alert: rateChange,
            myCompetitorsPercentRateChange: competitorsRateChange,
            howManyCompetitorsRateChange: competitorsCount,
        } = this.intraday;

        this.intraday.rateChangePercent2Alert = max(5, rateChange);
        this.intraday.myCompetitorsPercentRateChange = max(5, competitorsRateChange);
        this.intraday.howManyCompetitorsRateChange = max(2, min(15, competitorsCount));
    }

    async save() {
        this.statusText = '';
        await this.userSettings
            .saveIntradaySettings();
        this.isChanged = false;
        this.statusText = 'Saved!';
    }
}
