








import { Component, Vue } from 'vue-property-decorator';
import SubpageController from '../subpage-controller.vue';

import EmailReportSection from '../sections/email-reports.section.vue';
import EmailReportsSubpage from '../subpages/email-reports.subpage.vue';

@Component({
    components: {
        SubpageController,
        EmailReportSection,
    },
})
export default class ReportPropertiesTab extends Vue {
    static title = 'E-Mail Reports';

    subpages = {
        emailReports: EmailReportsSubpage,
    };
}
