











































import {
    Component,
    Prop,
    Provide,
    Vue,
} from 'vue-property-decorator';

@Component({})
export default class SubpageController extends Vue {
    @Prop({
        type: Object,
        default: () => ({}),
    })
    public subpages!: { [k: string]: Vue };
    public currentSubpage = '';

    updateQueryParameter() {
        const { subpage } = this.$route.query;

        if (subpage === this.currentSubpage) return;

        this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$route.query,
                subpage: this.currentSubpage,
            },
            params: {
                ...this.$route.params,
            },
        });
    }

    beforeMount() {
        const { subpage = '' } = this.$route.query;
        this.currentSubpage = subpage as string;
    }

    @Provide()
    public setSubpage(key: string) {
        this.currentSubpage = key;
        this.updateQueryParameter();
    }

    @Provide()
    public closeSubpage() {
        this.currentSubpage = '';
        this.updateQueryParameter();
    }

    public get subpageComponent() {
        if (this.currentSubpage === '') return null;

        return this.subpages[this.currentSubpage];
    }

    public get isSubpageOpened() {
        return !!this.subpageComponent;
    }

    public get slotScope() {
        return {
            setSubpage: this.setSubpage.bind(this),
            closeSubpage: this.closeSubpage.bind(this),
        };
    }
}
