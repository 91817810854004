























































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

import Item from '@/modules/common/interfaces/item.interface';
import HotelModel from '@/modules/hotels/models/hotel.model';

import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserSettingsService, { UserSettingsS } from '../user-settings.service';
import UserService, { UserServiceS } from '../user.service';
import HOTEL_NOTIFICATIONS from '../constants/hotel-level-notifications';

@Component({
    components: {
        CustomMultiSelect,
        CustomCheckbox,
        ExpansionPanel,
    },
})
export default class CompetitorAlertFilter extends Vue {
    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    get currentAlert() {
        const { currentHotelId } = this.userService;
        const { hotelAlertsAndNotifications } = this.userSettingsService;

        if (!hotelAlertsAndNotifications || !currentHotelId || !hotelAlertsAndNotifications[currentHotelId]) {
            return null;
        }

        return hotelAlertsAndNotifications[currentHotelId][HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE];
    }

    get currentValue() {
        if (!this.currentAlert || !this.currentAlert.fornovaIds) {
            return [] as Item[];
        }

        return this.currentAlert.fornovaIds.map(id => {
            const item = this.options.find(option => option.value === id);
            return {
                name: item ? item.name : String(id),
                value: id,
            };
        });
    }

    set currentValue(values: Item[]) {
        const { hotels, loading } = this.userSettingsService.storeState.hotelAlertsAndNotifications;

        if (!hotels || values.length > 2) {
            return;
        }

        const hotelId = parseInt(Object.keys(hotels)[0], 10);

        this.$emit('change', HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE);

        if (values.length === 0) {
            this.active = false;
        }

        this.userSettingsService.storeState.hotelAlertsAndNotifications = {
            hotels: {
                [hotelId]: {
                    [HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE]: {
                        fornovaIds: values.map(item => Number(item.value)),
                        active: this.active,
                        options: hotels[hotelId][HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE].options,
                        conditionsValue: hotels[hotelId][HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE].conditionsValue || 15,
                    },
                },
            },
            loading,
        };
    }

    get options(): Item[] {
        const { currentHotelId } = this.userService;
        return this.hotelsService.allHotels.filter((hotel: HotelModel) => hotel.id !== currentHotelId).map(hotel => ({
            name: hotel.name,
            value: hotel.id,
        }));
    }

    get active() {
        if (!this.currentAlert || this.currentAlert.active === undefined) {
            return false;
        }

        return this.currentAlert.active;
    }

    set active(value: boolean) {
        const { currentHotelId } = this.userService;
        const { hotelAlertsAndNotifications } = this.userSettingsService;

        if (!hotelAlertsAndNotifications || !currentHotelId || !hotelAlertsAndNotifications[currentHotelId]) {
            return;
        }

        hotelAlertsAndNotifications[currentHotelId][HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE].active = value;

        this.$emit('change', HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE);
    }

    get percent() {
        const { currentHotelId } = this.userService;
        const { hotelAlertsAndNotifications } = this.userSettingsService;

        if (!hotelAlertsAndNotifications || !currentHotelId || !hotelAlertsAndNotifications[currentHotelId]) {
            return 15;
        }

        return hotelAlertsAndNotifications[currentHotelId][HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE].conditionsValue || 15;
    }

    set percent(value: number) {
        const { currentHotelId } = this.userService;
        const { hotelAlertsAndNotifications } = this.userSettingsService;

        if (!hotelAlertsAndNotifications || !currentHotelId || !hotelAlertsAndNotifications[currentHotelId]) {
            return;
        }

        hotelAlertsAndNotifications![currentHotelId!]![HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE]
            .conditionsValue = +value;

        this.$emit('change', HOTEL_NOTIFICATIONS.COMPETITOR_RATE_CHANGE);
    }

    public validatePercent(e: Event) {
        const input = e.currentTarget as HTMLInputElement;

        const max = Math.max(+input.value, 15);
        this.percent = max;

        input.value = String(max);
    }
}
