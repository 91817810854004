export default {};

export interface FieldProperty {
    descriptionTemplate?: string;
    fields?: {
        default: any,
        unit: any,
        title?: string,
        attrs: {
            type: 'number',
        },
        fix?: (value: any) => any;
    }[];
}

export interface FieldPropertySet {
    [key: string]: FieldProperty;
}

export const fieldsConfig: FieldPropertySet = {
    rateAnomaly: {
        descriptionTemplate: 'The hotel\'s rate is higher/lower than the compset median by {0}',
        fields: [
            {
                default: 50,
                unit: '%',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    rateChange: {
        descriptionTemplate: 'Today\'s median rate is lower/higher than yesterday\'s '
            + 'median rate by +/-{0}',
        fields: [
            {
                default: 15,
                unit: '%',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    trendDetected: {
        descriptionTemplate: 'At least {0} in the compset '
            + 'have changed their rate by more than {1}',
        fields: [
            {
                default: 1,
                unit: 'hotels',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return +Math.max(1, value);
                },
            },
            {
                default: 15,
                unit: '%',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    visibilityDramaticChange: {
        descriptionTemplate: 'Hotel\'s sort order was dropped/improved by at least {0}',
        fields: [
            {
                default: 10,
                unit: 'points',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    mealPlanChange: {
        descriptionTemplate: 'At least {0} of the competitors has a new meal plan',
        fields: [
            {
                default: 10,
                unit: '',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    guestReviewScoreDecrease: {
        descriptionTemplate: "The hotel's guest score was decreased by {0}",
        fields: [
            {
                default: 0.1,
                unit: 'points',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return Math.max(0, value);
                },
            },
        ],
    },
    compsetLosRestrictions: {
        descriptionTemplate: 'At least 1 hotel in the compset had set LOS {0} restrictions',
        fields: [
            {
                default: 2,
                unit: '',
                attrs: {
                    type: 'number',
                },
                fix(value: number) {
                    return value <= 2 ? 2 : 3;
                },
                title: 'Only values 2 or 3 acceptable',
            },
        ],
    },
};
